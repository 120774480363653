document.addEventListener("DOMContentLoaded", () => {
    const back_to_top = document.querySelector(".cd-top");
    if (!back_to_top) {
        return;
    }
    const OFFSET = 300,
        OFFSET_OPACITY = 1200;

    window.addEventListener("scrollend", () => {
        if (window.scrollY > OFFSET) {
            back_to_top.classList.add("cd-is-visible");
        } else {
            back_to_top.classList.remove("cd-is-visible", "cd-fade-out");
        }

        if (window.scrollY > OFFSET_OPACITY) {
            back_to_top.classList.add("cd-fade-out");
        } else {
            back_to_top.classList.remove("cd-fade-out");
        }
    });

    back_to_top.addEventListener("click", () => {
        window.scrollTo({ top: 0 });
    });
});
