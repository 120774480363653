import { createObserver } from "./create-observer";

const observer = createObserver(0.1);
document.addEventListener("DOMContentLoaded", () => {
    document
        .querySelectorAll("video[data-video-src]")
        .forEach((/** @type HTMLVideoElement */ video) => {
            observer.observe(video);
            video.addEventListener(
                "intersecting",
                () => {
                    video.src = video.dataset.videoSrc;
                    video.removeAttribute("data-video-src");
                    observer.unobserve(video);
                },
                { once: true }
            );
        });
});
