import "bootstrap/js/dist/offcanvas";

document.addEventListener('DOMContentLoaded', function(){
    const offcanvasMenu = document.getElementById('offcanvasMenu');
    offcanvasMenu.addEventListener('shown.bs.offcanvas', function(){
        setTimeout(function(){
            offcanvasMenu.classList.add('no-animate'); // menu kapanirken animasyonu kapatir
        }, 1000);
    } );

    offcanvasMenu.addEventListener('hidden.bs.offcanvas', function(){
        offcanvasMenu.classList.remove('no-animate');
    } );
} );