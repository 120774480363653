let didScroll,
    lastScrollTop,
    header;
const SCROLL_DELTA = 5;

window.addEventListener('scroll', () => {
    if(document.body.classList.contains('offcanvas-backdrop')
    || document.body.classList.contains('fancybox-active')) {
        return;
    }
    didScroll = true;
});

setInterval( () => {
    if (!didScroll) {
        return;
    }
    hasScrolled();
}, 200 );

function hasScrolled() {
    isHeaderSticky();
    isHeaderHidden();
    lastScrollTop = window.scrollY;
    didScroll = false;
}

function isHeaderSticky() {
    if(!header) {
        return;
    }
    if ( window.scrollY > header.offsetHeight * 2 ) {
        header.classList.add('header--is-sticky');
        setTimeout( () => {
            header.classList.add('header--has-transition');
        }, 300 );
        return;
    }
    header.classList.remove('header--is-sticky');
    header.classList.remove('header--has-transition');
}

function isHeaderHidden() {
    //Yukari scrollda gorunsun
    if ( window.scrollY < lastScrollTop - SCROLL_DELTA ) {
        header.classList.remove('header--is-hidden');
        return;
    }

    header.classList.add('header--is-hidden');
}

document.addEventListener('DOMContentLoaded', () => {
    header = document.querySelector('.header');
});