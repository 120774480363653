import { createObserver } from "./create-observer";
import { Fancybox } from "@fancyapps/ui";

const loopVideos = document.querySelectorAll("video[loop]");
let observer = createObserver(0.5);

function pauseLoopVideos() {
    loopVideos.forEach((video) => {
        if (video.paused) return;
        video.pause();
    });
}

function playLoopVideos() {
    loopVideos.forEach((video) => {
        if (!video.paused) return;
        video.play();
    });
}

// begin: loop autoplay
// https://benfrain.com/automatically-play-and-pause-video-as-it-enters-and-leaves-the-viewport-screen/
function playPauseVideo() {
    //console.log('playPauseVideo videos:', loopVideos);
    loopVideos.forEach((video) => {
        observer.observe(video);
        video.addEventListener("intersecting", () => {
            if (video.paused) {
                video.play();
            }
        });
        video.addEventListener("not-intersecting", () => {
            video.pause();
        });
    });
}

// And you would kick this off where appropriate with:
document.addEventListener("DOMContentLoaded", () => {
    Fancybox.bind(`
        a[href*="vimeo.com"]:not([target="_blank"]),
        a[href*="youtube.com"]:not([target="_blank"])
    `, {
        compact: true,
        tpl: {
            closeButton: "<button data-fancybox-close class='fancybox-custom-close'></button>",
        },
        on: {
            close: () => {
                playLoopVideos();
            },
            reveal: () => {
                pauseLoopVideos();
            }
        }
    });
    playPauseVideo();

    loopVideos.forEach((video) => {
        const data_loop_video_plays = video.closest("[data-loop-video-plays]");
        if (!data_loop_video_plays) {
            return;
        }
        video.addEventListener("play", () => {
            data_loop_video_plays.setAttribute("data-loop-video-plays", "true");
            data_loop_video_plays
                .querySelectorAll(".kr-hide-on-loop-video-plays")
                .forEach((poster) => {
                    poster.remove();
                });
        });
        video.addEventListener("pause", () => {
            data_loop_video_plays.setAttribute(
                "data-loop-video-plays",
                "false"
            );
        });
    });

    // begin: loop video gizle goster
    const overlay_videos = document.querySelectorAll(
        ".pb-video-single_overlay-video"
    );

    overlay_videos.forEach((ov) => {
        const mask = ov.closest(".pb-ratio-mask");
        if (!mask) {
            return;
        }
        const loopVideo = mask.querySelector(
            "video[loop].hide-loop-video-on-pause"
        );
        if (!loopVideo) {
            return;
        }
        ov.addEventListener("play", () => {
            loopVideo.style.opacity = "0";
        });
        ov.addEventListener("pause", () => {
            loopVideo.style.opacity = "";
        });
    });
    // end: loop video gizle goster
});
// end: loop autoplay
