//kr-e-bulten
document.addEventListener("DOMContentLoaded", () => {
    const kr_e_bulten = document.querySelector('[data-e-bulten="form"]');
    if (!kr_e_bulten) {
        return;
    }

    const MIN_HEIGHT_PROPERTY = "--kr-e-bulten-min-height";

    kr_e_bulten.style.setProperty(
        MIN_HEIGHT_PROPERTY,
        kr_e_bulten.offsetHeight + "px"
    );

    window.addEventListener("resize", () => {
        kr_e_bulten.style.removeProperty(MIN_HEIGHT_PROPERTY);
        kr_e_bulten.style.setProperty(
            MIN_HEIGHT_PROPERTY,
            kr_e_bulten.offsetHeight + "px"
        );
    }); //window.resize

    const kr_e_bulten_input = kr_e_bulten.querySelector(
            '[data-e-bulten="input"]'
        ),
        kr_e_bulten_result = kr_e_bulten.querySelector(
            '[data-e-bulten="result"]'
        ),
        kr_e_bulten_message = kr_e_bulten.querySelector(
            '[data-e-bulten="message"]'
        ),
        kr_e_bulten_permission = kr_e_bulten.querySelector(
            '[data-e-bulten="permission"]'
        ),
        kr_e_bulten_email = kr_e_bulten.querySelector(
            '[data-e-bulten="email"]'
        );

    console.log({ kr_e_bulten_email });
    if (
        !kr_e_bulten_input ||
        !kr_e_bulten_result ||
        !kr_e_bulten_message ||
        !kr_e_bulten_permission ||
        !kr_e_bulten_email
    ) {
        return;
    }

    function displayResult() {
        kr_e_bulten_input.classList.add("d-none");
        kr_e_bulten_result.classList.remove("d-none");
    }

    function ebultenMessage(messageType) {
        kr_e_bulten_message.innerText =
            kr_e_bulten_message.dataset[messageType];
        displayResult();
        if (messageType !== "eBultenSuccessThankYou") {
            redisplayInputs(3000);
        }
    }

    function redisplayInputs(delay) {
        setTimeout(() => {
            kr_e_bulten_message.innerText = "";
            kr_e_bulten_result.classList.add("d-none");
            kr_e_bulten_input.classList.remove("d-none");
        }, delay);
    }

    kr_e_bulten.addEventListener("submit", (e) => {
        e.preventDefault();
        if (!kr_e_bulten_permission.checked) {
            ebultenMessage("eBultenPermissionRequired");
            return;
        }
        if (kr_e_bulten_email.value.length === 0) {
            ebultenMessage("eBultenEMailRequired");
            return;
        }
        kr_e_bulten_message.innerText =
            kr_e_bulten_message.dataset.eBultenLoading;
        displayResult();
        if (!("template_url" in window)) {
            return;
        }

        const ebultenFormData = new FormData(kr_e_bulten);

        window
            .fetch(window.template_url + "/handlers/e-bulten.php", {
                method: "POST",
                body: ebultenFormData,
            })
            .then((r) => r.json())
            .then((response) => {
                switch (response.message) {
                    case "FAIL_EMAIL_MISSING":
                        ebultenMessage("eBultenFailEmailMissing");
                        break;
                    case "FAIL_EMAIL_INVALID":
                        ebultenMessage("eBultenFailEmailInvalid");
                        break;
                    case "FAIL_ALREADY_MEMBER":
                        ebultenMessage("eBultenFailAlreadyMember");
                        break;
                    case "SUCCESS_THANK_YOU":
                        ebultenMessage("eBultenSuccessThankYou");
                        break;
                }
            });
    }); // end submit eventlistener
}); //document.DOMContentLoaded
