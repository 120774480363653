document.addEventListener( 'DOMContentLoaded', () => {
    const kr_loop_overlay_videos = document.querySelectorAll('.kr-loop-overlay-video,.pb-video-single_overlay-video');
    kr_loop_overlay_videos.forEach(video => {

        function videoClickTouch() {
            if (this.paused) {
                this.play();
                return;
            }
            this.pause();
        }

        video.addEventListener('click', videoClickTouch);
        video.addEventListener('touch', videoClickTouch);

        video.addEventListener('play', () => {
            video.style.opacity = '1';
            video.setAttribute('controls', '');
            if(video.closest('.pb-ratio-mask') && video.closest('.pb-ratio-mask').querySelector('.pb-video-play')) {
                video.closest('.pb-ratio-mask').querySelector('.pb-video-play').classList.add('d-none');
            }
        });

        video.addEventListener('pause', () => {
            video.style.opacity = '0';
            video.removeAttribute('controls');
            if(video.closest('.pb-ratio-mask') && video.closest('.pb-ratio-mask').querySelector('.pb-video-play')) {
                video.closest('.pb-ratio-mask').querySelector('.pb-video-play').classList.remove('d-none');
            }
        });
    });
} );